import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout";
import Showcase from "../components/Showcase";
import Cta from "../components/Cta";

import Hat from "../images/hat.svg";
import Rabbit from "../images/rabbit.svg";

import HomepageiPadSplash from "../images/ipad-web-homepage-splash.jpg"
import CheckIcon from "../images/check-icon.png"
import ContactBg from "../images/working-together.png"

import "../styles/homepage.css"

function Home() {
  return (
    <Layout animated={true} >

      <div className="intro-header">
        <div className="hat-image animated fadeIn" style={{ backgroundImage: `url(${Rabbit})`}}/>
        {/*<div className="rabbit-image animated bounceIn" style={{ backgroundImage: `url(${Rabbit})`}}/>*/}
        <h1 className="intro-title animated fadeIn">ETD Interactive</h1>
        <h2 className="intro-subtitle animated fadeIn">modern web design and development</h2>
      </div>

      <div className="section-title">Your Website. Beautifully Built.</div>
      <hr className="section-title-underline" />

      <div className="spacer"/>

      <div className="homepage-splash" style={{ backgroundImage: `url(${HomepageiPadSplash})` }}>
        <div className="homepage-splash-content container">
          <div className="content-title">What we do.</div>
          <div className="content-description">We <b>design</b> and <b>engineer</b> beautiful, modern websites to fit your business needs, boost brand value, and increase revenue through customer engagement.</div>
        </div>
      </div>

      <div className="container">
        <div className="content-title">What sets us apart.</div>
        <div className="content-description">Our top industry experience with companies including <b>Apple</b>, <b>Facebook</b> and <b>Blizzard Entertainment</b> sets us apart. Anyone can call themselves a web developer nowadays and claim to be able to 'make a website' for you, but it won't do much for your business. We bring out expertise in designing and engineering revenue boosting, engaging websites for some of the most successful companies in the world to you.</div>
        <Link to="/portfolio"><div className="cta-button cta-button-small">see our work</div></Link>
      </div>


      <div className="spacer"/>

      <div className="section-title">We've Got Your Back.</div>
      <hr className="section-title-underline" />

      <div className="container">

        <div className="big-checklist">
          <div className="big-checklist-icon" style={{ backgroundImage: `url(${CheckIcon})`}}></div>
          <div className="content-title">We work with integrity.</div>
          <div className="content-description">We love designing and creating. Everything we build, we are proud to stand behind because it reflects the quality and excellence that we are known to deliver. We are no middlemen, and we do not outsource or subcontract out the project you have trusted us to complete.</div>
          <Link to="/portfolio"><div className="cta-button cta-button-small">see our portfolio</div></Link>
        </div>

        <div className="big-checklist">
          <div className="big-checklist-icon" style={{ backgroundImage: `url(${CheckIcon})`}}></div>
          <div className="content-title">Transparent Pricing</div>
          <div className="content-description">We understand that trying to figure out how much a website will cost is difficult, and we don't want to make that harder. We will give you a quote for the total cost and time it will take to compete. That way you know exactly what you are paying, what you are getting, and when your new website will be online.</div>
          <Link to="/services"><div className="cta-button cta-button-small">see our services</div></Link>
        </div>

        <div className="big-checklist">
          <div className="big-checklist-icon" style={{ backgroundImage: `url(${CheckIcon})`}}></div>
          <div className="content-title">Fast communication.</div>
          <div className="content-description">They say communication is everything, and it's true. We will keep you in the loop at every step of your project so you never feel like you don't know what's going on behind the scenes. You will have access to a preview website so you can see real time design and development progress, and check up on your project anytime at your convenience.</div>
          <Link to="/contact"><div className="cta-button cta-button-small">contact us</div></Link>
        </div>

      </div>


      <hr className="divider" />

      <Cta title="interested in working with us?" buttonText="contact" link="/contact" bg={ContactBg}/>

    </Layout>
  )
}

export default Home;
